<script>
import adaptHtml from "@/helpers/adaptHtml"
export default {
    components: { },
    data(){
        return {
           bookingItem: null,
           bookingCanceled: false
        }
    },
    created() {   
        this.getBooking();
    },
    methods:
    {
        getBooking()
        {
            var self = this;     
            this.currentDate = new Date();
            this.$communicator.get('public-booking/' + this.$route.params.id)
                .then(function(data)
                {             
                  self.bookingItem = data;
                })
                .fail(function(error) {
                    
                });   
        },
        cancelBooking()
        {
            var self = this;     
            this.currentDate = new Date();
            this.$communicator.post('public-booking/' + this.$route.params.id)
                .then(function(data)
                {
                    $("html").scrollTop(0);
                    self.bookingCanceled = true;
                })
                .fail(function(error) {
                    
                });   
        },
    }
  }

</script>
<template>
    <div class="BookingDetails-section" v-if="bookingItem != null && !bookingCanceled" v-fade-in>
        <h2>Hej {{ bookingItem.full_name }}! Snart är det dags för din bokning av {{ bookingItem.booking_item_name }} :)</h2>
        <p>Här hittar du all information du behöver om din bokning och även möjligheten att avboka den.</p> 
        <div class="BookingShow-info-box">           
            <div><strong>När:</strong> {{ bookingItem.booked_period }} </div>
            <div v-if="bookingItem.booking_item_public_hidden_info != null"><strong>Extra Information om bokningen:</strong> <span v-html="bookingItem.booking_item_public_hidden_info"></span></div>            
        </div>
        <h2>Information</h2>
        <div v-html="bookingItem.booking_item_information">
        </div>
        <div style="padding: 20px; margin: 30px 0px;background-color:#cccccc">
            <div v-html="bookingItem.booking_item_rules">
            </div>
        </div>

        <button class="btn" @click="cancelBooking()">Avboka</button>
    </div>
    <div  class="BookingDetails-section" v-if="bookingCanceled">
        <h2>Avbokningen lyckades!</h2>
        <p>Hoppas vi syns någon annan gång! :)</p>
    </div>
</template>
<style scoped>
    .BookingDetails-section
    {
        padding:20px;
    }

    .BookingShow-info-box
    {
        padding: 20px;
        margin: 30px 0px;
        border-radius: 5px;
    }

    .BookingShow-info-box{
        line-height: 30px;
        background-color:#cccccc;
    }


</style>